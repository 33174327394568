import { DateTime } from "luxon";

export const TwentyFourHourTimeFormat = "HH:mm ZZZZ";
export const TwelveHourTimeFormat = "hh:mm a ZZZZ";
export const DateFormat = "MM/dd/yy";

export const TwentyFourHourDateTimeFormat = `${DateFormat} ${TwentyFourHourTimeFormat}`;
export const TwelveHourDateTimeFormat = `${DateFormat} ${TwelveHourTimeFormat}`;

/**
 * @description Converts an ISO string to a date time string setting the zone to the system tz or a specified zone.
 * @author Jacob Cutshall
 */
export default function convertIsoToDateTimeString(
  isoString: string,
  options?: {
    twelveHourFormat?: boolean;
    zone?: string;
  },
) {
  const { twelveHourFormat = false, zone = "system" } = options ? options : {};

  const dateObj = DateTime.fromISO(isoString, { zone: "utc" }).setZone(zone);

  if (twelveHourFormat) {
    return dateObj.toFormat(TwelveHourDateTimeFormat);
  }

  return dateObj.toFormat(TwentyFourHourDateTimeFormat);
}

/**
 * The function `convertIsoToDate` takes an ISO string and converts it to a formatted date string in
 * the specified time zone.
 * @param {string} isoString - The `isoString` parameter is a string representing a date and time in
 * ISO 8601 format, such as "2022-01-15T08:30:00Z".
 * @param [zone=utc] - The `zone` parameter in the `convertIsoToDate` function specifies the time zone
 * to use when converting the ISO string to a date. By default, it is set to "utc", but you can provide
 * a different time zone if needed.
 * @returns The function `convertIsoToDate` takes an ISO string and an optional time zone parameter,
 * then converts the ISO string to a DateTime object using the specified time zone (default is "utc"),
 * and finally returns the formatted date in the "MM/dd/yy" format.
 */
export function convertIsoToDate(isoString: string, zone = "utc") {
  // should we set the zone to system before format? I know this is used for subscription dates so mabe it should stay UTC
  return DateTime.fromISO(isoString, { zone: "utc" }).toFormat("MM/dd/yy");
}

export function convertISOToJSDate(isoString: string) {
  return DateTime.fromISO(isoString, { zone: "utc" }).toJSDate();
}

export function convertJSDateToDateTimeString(
  jsDate: Date,
  options?: {
    twelveHourFormat?: boolean;
    zone?: string;
  },
) {
  const { twelveHourFormat = false, zone = "system" } = options ? options : {};
  const dateTimeObj = DateTime.fromJSDate(jsDate).setZone(zone);

  if (twelveHourFormat) {
    return dateTimeObj.toFormat(TwelveHourDateTimeFormat);
  } else {
    return dateTimeObj.toFormat(TwentyFourHourDateTimeFormat);
  }
}
